/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);






// WOW and google shit

    jQuery(document).ready(function($){

            wow = new WOW(
        {
          offset:  200 
        }
      )
      wow.init();
        
        $(window).on('load',function(){
        if($(window).width() > 768){ 
         
        }         
    });
        });









// Hopefully that was good

// CLEAR HEADER
$(function() {
    //caches a jQuery object containing the header element
    var header = $(".clearheader");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            header.removeClass('clearheader').addClass("darkheader");
        } else {
            header.removeClass("darkheader").addClass('clearheader');
        }
    });
});




// SLIDER


$(document).ready(function(){
  $('.bxslider').bxSlider();
});

            jQuery(function($) {
                $('#main-slider .bx-slider').bxSlider({
                    adaptiveHeight: true,
                    pager: true,
                    controls: true,
                    mode: 'fade',
                    auto: true,
                    pause: '5500',
                    speed: '5000'
                });
            });







// tween lite the menu scrolll


$("li.menu-portfolio").click(function(e) {
  TweenLite.to(window, 2, {scrollTo:{y:"#portfolio", offsetY:70}});
});

$("li.menu-contact").click(function(e) {
  TweenLite.to(window, 2, {scrollTo:{y:"#contact", offsetY:70}});
});








// google analytics
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-49474891-1', 'auto');
  ga('send', 'pageview');




// AND THE MAP SHIT (i need to fix I tihnk)













})(jQuery); // Fully reference jQuery after this point.

